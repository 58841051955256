:root {
    --primary-color: #FF4D4D;
    --secondary-color: #4A90E2;
    --background-color: #F5F7FA;
    --text-primary: #2C3E50;
    --text-secondary: #34495E;
    --card-background: #FFFFFF;
    --gradient-primary: linear-gradient(135deg, #FF4D4D 0%, #F9A825 100%);
    --shadow-elegant: 0 15px 35px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  }
  
  .partners {
    font-family: 'Poppins', 'Inter', sans-serif;
    background-color: var(--background-color);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    margin-top: 55px;
  }
  
  .partners-container {
    display: flex;
    background: var(--card-background);
    border-radius: 20px;
    box-shadow: var(--shadow-elegant);
    max-width: 1200px;
    width: 100%;
    overflow: hidden;
  }
  
  .partners-info {
    flex: 0 0 45%;
    padding: 50px;
    background: var(--gradient-primary);
    color: white;
  }
  
  .partners-info h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .partners-info p {
    font-size: 1.1rem;
    margin-bottom: 30px;
    opacity: 0.9;
  }
  
  .partners-details {
    margin-top: 20px;
  }
  
  .partners-detail {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
  .partners-detail span {
    margin-left: 10px;
  }
  
  .partners-form {
    flex: 0 0 55%;
    padding: 50px;
    background: var(--background-color);
  }
  
  .partners-form h3 {
    font-size: 2rem;
    margin-bottom: 30px;
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .form-group {
    position: relative;
    margin-bottom: 20px;
  }
  
  .partners-form input {
    width: 100%;
    padding: 15px 15px 15px 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .partners-form input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(255, 77, 77, 0.1);
  }
  
  .submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    background: var(--gradient-primary);
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .submit-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
  }
  
  @media (max-width: 1024px) {
    .partners-container {
      flex-direction: column;
    }
  
    .partners-info,
    .partners-form {
      flex: 1 1 100%;
      padding: 30px;
    }
  }
  