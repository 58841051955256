:root {
    --primary-color: #FF4D4D;
    --secondary-color: #4A90E2;
    --background-color: #F5F7FA;
    --text-primary: #2C3E50;
    --text-secondary: #34495E;
    --card-background: #FFFFFF;
    --gradient-primary: linear-gradient(135deg, #FF4D4D 0%, #F9A825 100%);
    --gradient-secondary: linear-gradient(135deg, #4A90E2 0%, #6A5ACD 100%);
    --shadow-elegant: 0 15px 35px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  }
  
  .contact {
    font-family: 'Poppins', 'Inter', sans-serif;
    background-color: var(--background-color);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
  }
  
  .contact-container {
    margin-top: 100px;
    display: flex;
    background: var(--card-background);
    border-radius: 20px;
    box-shadow: var(--shadow-elegant);
    max-width: 1200px;
    width: 100%;
    overflow: hidden;
  }
  
  .contact-info {
    flex: 0 0 45%;
    padding: 50px;
    background: var(--gradient-primary);
    color: white;
    position: relative;
  }
  
  .contact-info::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: 
      radial-gradient(circle at top right, rgba(255,255,255,0.1) 0%, transparent 50%);
    z-index: 1;
  }
  
  .contact-info h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: white;
  }
  
  .contact-subtitle {
    font-size: 1.1rem;
    margin-bottom: 30px;
    opacity: 0.9;
  }
  
  .contact-details {
    margin-bottom: 30px;
  }
  
  .contact-detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .contact-icon {
    margin-right: 15px;
    color: white;
    opacity: 0.8;
  }
  
  .contact-detail h4 {
    margin: 0;
    font-size: 1.1rem;
  }
  
  .contact-detail p {
    margin: 5px 0 0;
    opacity: 0.7;
  }
  
  .social-links h4 {
    margin-bottom: 15px;
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icon {
    color: white;
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  .social-icon:hover {
    opacity: 1;
  }
  
  .contact-form {
    flex: 0 0 55%;
    padding: 50px;
    background: var(--background-color);
  }
  
  .contact-form h3 {
    font-size: 2rem;
    margin-bottom: 30px;
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .form-group {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .form-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-secondary);
    opacity: 0.6;
    pointer-events: none; /* Empêche l'icône d'intercepter les clics */
    z-index: 1; /* L'icône reste visible mais ne bloque pas */
  }
  
  
  .contact-form input,
.contact-form textarea {
  background-color: transparent;
  position: relative; /* Nécessaire pour le z-index */
  z-index: 2; /* Place les champs au-dessus des icônes */
  width: 100%;
  padding: 15px 15px 15px 45px; /* Ajuste le padding pour l'icône */
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
  padding-left: 50px;
}

  
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(255, 77, 77, 0.1);
  }
  
  
  .contact-form textarea {
    height: 150px;
    resize: vertical;
  }
  
  .submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    background: var(--gradient-primary);
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .submit-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(255,77,77,0.3);
  }
  
  .btn-icon {
    margin-right: 10px;
  }
  
  @media (max-width: 1024px) {
    .contact-container {
      flex-direction: column;
    }
  
    .contact-info, .contact-form {
      flex: 1 1 100%;
      padding: 30px;
    }
  }