:root {
    --primary-color: #FF4D4D;
    --secondary-color: #e97c16;
    --background-color: #F5F7FA;
    --text-primary: #50402c;
    --text-secondary: #34495E;
    --card-background: #FFFFFF;
    --gradient-primary: linear-gradient(135deg, #FF4D4D 0%, #F9A825 100%);
    --gradient-secondary: linear-gradient(135deg, #4A90E2 0%, #6A5ACD 100%);
    --shadow-elegant: 0 15px 35px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  }
  
  .about {
    font-family: 'Poppins', 'Inter', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px;
    background-color: var(--background-color);
    position: relative;
    overflow: hidden;
  }
  
  .about::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: 
      radial-gradient(circle at top right, rgba(255,77,77,0.1) 0%, transparent 50%),
      radial-gradient(circle at bottom left, rgba(74,144,226,0.1) 0%, transparent 50%);
    z-index: 1;
  }
  
  .about-title {
    margin-top: 100px;
    text-align: center;
    font-size: 3.5rem;
    margin-bottom: 30px;
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 2;
  }
  
  .about-intro {
    text-align: center;
    font-size: 1.3rem;
    color: var(--text-secondary);
    max-width: 800px;
    margin: 0 auto 50px;
    margin-top: 100px;
    position: relative;
    z-index: 2;
  }
  
  .about-features {
    background: var(--card-background);
    border-radius: 20px;
    padding: 40px;
    box-shadow: var(--shadow-elegant);
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
  }
  
  .about-features h3 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .about-features::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: var(--gradient-primary);
  }
  
  .about-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  
  .about-list li {
    flex: 1;
    text-align: center;
    padding: 20px;
    margin: 0 15px;
    border-radius: 15px;
    transition: all 0.4s ease;
    position: relative;
    font-size: 1.5rem;
  }
  .solution {
    color: var(--text-secondary);
    line-height: 1.6;
    font-size: 1.2rem;
  }
  
  .about-list li:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }
  
  .about-list li strong {
    display: block;
    margin-top: 15px;
    color: var(--primary-color);
  }
  
  .about-restaurateurs {
    background-color: white;
    border-radius: 20px;
    padding: 50px;
    box-shadow: var(--shadow-elegant);
  }
  
  .restaurateurs-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 40px;
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .restaurateurs-benefits {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  
  .benefit {
    flex: 1;
    background: var(--background-color);
    border-radius: 20px;
    padding: 30px;
    transition: all 0.4s ease;
    position: relative;
    overflow: hidden;
  }
  
  .benefit::after {
    content: '';
    position: absolute;
    bottom: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at bottom right, 
      rgba(255,77,77,0.1) 0%, 
      transparent 50%);
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  
  .benefit:hover::after {
    opacity: 1;
  }
  
  .benefit:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
  
  .benefit h4 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: var(--primary-color);
    display: flex;
    align-items: center;
  }
  
  .benefit h4 span {
    margin-right: 15px;
    font-size: 2rem;
  }
  
  .benefit p {
    color: var(--text-secondary);
    line-height: 1.6;
  }
  
  @media (max-width: 1024px) {
    .about-list,
    .restaurateurs-benefits {
      flex-direction: column;
    }
    .restaurateurs-title {
      text-align: center;
      font-size: 1.6rem;
    }
  
    .about-list li,
    .benefit {
      margin-bottom: 20px;
    }

    .about-features h3 {
      font-size: 1.6rem;
    }
  
    .about-title {
      font-size: 2rem;
    }
  }