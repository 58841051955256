/* Header global styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

/* Logo */
.logo-img {
  height: 70px;
  width: auto;
}

/* Navigation styles */
.nav {
  display: flex;
}

.nav ul {
  display: flex;
  gap: 30px;
  list-style-type: none;
  margin: 0;
}

.nav ul li a {
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  font-weight: bold;
  transition: color 0.3s, transform 0.3s;
}

.nav ul li a:hover {
  color: #ff5722;
  transform: scale(1.1);
}

/* Menu hamburger button */
.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  z-index: 101; /* Au-dessus du menu */
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #333;
  transition: all 0.3s;
}

/* Hamburger animations when opened */
.hamburger.open .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Styles pour la navigation responsive */
.nav {
  display: none; /* Cacher par défaut */
  position: fixed;
  top: 70px; /* Sous le header */
  right: 0;
  background: white;
  width: 100%;
  height: calc(100vh - 70px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  transform: translateX(100%); /* Navigation hors de l'écran */
}

.nav.open {
  display: flex;
  transform: translateX(0); /* Navigation visible */
}

.nav ul {
  flex-direction: column;
  gap: 20px;
}

.nav ul li a {
  font-size: 1.5rem;
}

/* Hamburger visible sur petit écran */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav {
    display: flex;
  }
}

/* Navigation en mode bureau */
@media (min-width: 769px) {
  .nav {
    display: flex; /* Afficher la navigation */
    position: static; /* Réinitialiser le positionnement */
    flex-direction: row; /* Disposition horizontale */
    width: auto; /* Largeur automatique */
    height: auto; /* Hauteur automatique */
    transform: none; /* Supprimer la transformation */
    background: none; /* Pas de fond blanc */
    box-shadow: none; /* Pas d'ombre */
    gap: 30px; /* Espacement horizontal */
  }

  .nav ul {
    flex-direction: row; /* Réaligner horizontalement */
  }

  .nav ul li a {
    font-size: 1.1rem; /* Taille normale */
  }

  .hamburger {
    display: none; /* Cacher le bouton hamburger */
  }
}


  