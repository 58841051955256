.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
  }
  .footer img {
    height: 20px;
    vertical-align: middle;
  }
  