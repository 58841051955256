/* Advanced Elegant Design for Bonn'App */
:root {
  --primary-color: #FF4D4D;
  --secondary-color: #4A90E2;
  --background-color: #F5F7FA;
  --text-primary: #2C3E50;
  --text-secondary: #34495E;
  --card-background: #FFFFFF;
  --gradient-primary: linear-gradient(135deg, #FF4D4D 0%, #F9A825 100%);
  --gradient-secondary: linear-gradient(135deg, #4A90E2 0%, #6A5ACD 100%);
  --shadow-elegant: 0 15px 35px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

body {
  font-family: 'Poppins', 'Inter', sans-serif;
  background-color: var(--background-color);
  color: var(--text-primary);
  line-height: 1.6;
  overflow-x: hidden;
}

.home {
  max-width: 1600px;
  margin: 0 auto;
  overflow: hidden;
}

/* Elegant Hero Section */
.hero {
  display: flex;
  align-items: center;
  min-height: 90vh;
  padding: 60px 50px;
  background: linear-gradient(45deg, rgba(255,77,77,0.05), rgba(255,255,255,0.9));
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: 
    radial-gradient(circle at top right, rgba(255,77,77,0.1) 0%, transparent 50%),
    radial-gradient(circle at bottom left, rgba(74,144,226,0.1) 0%, transparent 50%);
  z-index: 1;
}

.hero-content {
  flex: 0 0 55%;
  z-index: 2;
  position: relative;
}

.hero-content h1 {
  font-size: 4rem;
  font-weight: 800;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 25px;
  line-height: 1.2;
}

.hero-content p {
  font-size: 1.3rem;
  color: var(--text-secondary);
  margin-bottom: 20px;
  max-width: 600px;
}

.hero-image {
  flex: 0 0 45%;
  position: relative;
  perspective: 1000px;
}

.hero-image img {
  border-radius: 20px;
  box-shadow: var(--shadow-elegant);
  transform: rotateY(-5deg) scale(1.05);
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.hero-image img:hover {
  transform: rotateY(0) scale(1.08);
}

.app-badges {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.app-badges img {
  max-width: 300px;
  border-radius: 15px;
  box-shadow: var(--shadow-elegant);
  transform: scale(1);
  transition: transform 0.4s ease;
}

.app-badges img:hover {
  transform: scale(1.05);
}

/* Features Section */
.features {
  background: white;
  padding: 100px 50px;
  text-align: center;
}

.features h2 {
  font-size: 2.5rem;
  margin-bottom: 60px;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-cards {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.feature-card {
  background: var(--card-background);
  border-radius: 20px;
  padding: 40px 30px;
  box-shadow: var(--shadow-elegant);
  transform: translateY(0);
  overflow: hidden;
  position: relative;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--gradient-primary);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s ease;
}

.feature-card:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.feature-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 25px;
  transition: transform 0.4s ease;
}

.feature-card:hover img {
  transform: scale(1.1);
}

/* Restaurants Section */
.for-restaurants {
  background-color: var(--background-color);
  padding: 100px 50px;
}

.for-restaurants h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 60px;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.benefits {
  display: flex;
  gap: 30px;
}

.benefit {
  flex: 1;
  background: var(--card-background);
  border-radius: 20px;
  padding: 40px 25px;
  text-align: center;
  box-shadow: var(--shadow-elegant);
  transform: translateY(0);
  position: relative;
  overflow: hidden;
}

.benefit::after {
  content: '';
  position: absolute;
  bottom: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at bottom right, 
    rgba(255,77,77,0.1) 0%, 
    transparent 50%);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.benefit:hover::after {
  opacity: 1;
}

.orange-icon {
  color: var(--primary-color);
  margin-bottom: 20px;
  transition: transform 0.4s ease;
}

.benefit:hover .orange-icon {
  transform: rotate(360deg);
}

/* Section Hero - Responsive */
@media (max-width: 768px) {
  .hero {
    margin-top: 30px;
    flex-direction: column; /* Passe en colonne */
    padding: 20px;
    min-height: auto;
  }

  .hero-content {
    text-align: center;
    margin-bottom: 20px;
  }

  .hero-content h1 {
    font-size: 2.5rem; /* Taille réduite sur mobile */
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-image img {
    width: 90%; /* Images plus petites */
    margin: 0 auto;
  }

  .app-badges img {
    width: 80%; /* Limiter la largeur */
    margin: 10px auto;
  }
  .for-restaurants h2 {
    font-size: 2rem;
  }
  .features h2 {
    font-size: 2rem;
  }
}

/* Features Section - Responsive */
@media (max-width: 768px) {
  .features {
    padding: 50px 20px; /* Réduire le padding */
  }

  .feature-cards {
    flex-direction: column; /* Passe en colonne */
    gap: 20px; /* Réduire l'espacement */
  }

  .feature-card img {
    height: auto; /* Hauteur fluide */
    max-height: 200px;
  }
}

/* Restaurants Section - Responsive */
@media (max-width: 768px) {
  .for-restaurants {
    padding: 50px 20px; /* Réduction générale */
  }

  .benefits {
    flex-direction: column; /* Passe en colonne */
    gap: 20px;
  }

  .benefit {
    padding: 20px;
  }

  .orange-icon {
    font-size: 2rem; /* Réduire la taille de l'icône */
  }
}

/* Global Responsive Adjustments */
@media (max-width: 768px) {
  h1, h2, h3 {
    font-size: 90%; /* Taille ajustée */
  }

  p {
    font-size: 0.9rem; /* Réduire légèrement les paragraphes */
  }
}
